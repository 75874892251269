import Select from 'react-select'

const SelectButton = ({stateData, handleItemSelect, setState, currVal}) => {

    return(
        <>
            <Select 
            styles={{
                control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: 'black',
                width : '15em',
                textAlign : 'center',
                backgroundColor: 'white'
                }),
                option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
                    ...styles,
                    color : '#111827',
                    backgroundColor : isSelected ? '#11182732' : isFocused ? '#11182717' : '',
                    ':active' : {
                        ...styles[':active'],
                        backgroundColor : '#11182717'
                    }
                }),
            }}
            value={currVal}
            onChange={(e) => handleItemSelect(setState, e)}
            isDisabled={stateData == null}
            options={stateData}/>
        </>
    )
}

export default SelectButton;